<template>
  <v-list three-line key="lista">
    <template v-for="(servico, index) in servicos">
      <v-list-tile
        :key="servico.chave+index"
        avatar
      >
        <v-list-tile-avatar>
              <v-img :src="urlToken(url+'/servico-imagem/'+servico.chave)" />
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title>{{ servico.data }} - {{ servico.cliente }}</v-list-tile-title>
          <v-list-tile-sub-title class="text--primary">
              {{ servico.tamanho }}'' / {{ servico.quantidade }} / {{ (servico.cor ? servico.cor : 'Sem Cor' ) }}<br>
              {{ servico.descricao }}
          </v-list-tile-sub-title>
          <v-list-tile-sub-title>{{ moment(servico.prazo).fromNow() }}</v-list-tile-sub-title>
        </v-list-tile-content>

        <v-list-tile-action>
          <v-list-tile-action-text>{{ moment(servico.update).fromNow(true) }}</v-list-tile-action-text>

          <v-menu bottom offset-y>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on"><v-icon>repeat</v-icon></v-btn>
            </template>
            <v-list>
              <v-list-tile flat v-for="produto in servico.departamentos" :disabled="!produto.id" :key="produto.id" @click="resetServico(produto)">
                <v-list-tile-title> Repetir <strong>{{ produto.departamento }}</strong></v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>

        </v-list-tile-action>
      </v-list-tile>
      <v-divider
        v-if="index + 1 < servicos.length"
        :key="index"
      ></v-divider>
    </template>
  </v-list>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Terminados',
  data: function() {
    return {
      servicos: [],
      timer: null,
      url: '',
    }
  },
  mounted(){
    let self = this
    moment.locale("pt")
    self.url = self.$http.defaults.baseURL
    self.getServicos()
  },
  created(){
    this.timer = setInterval(() => {
        this.getServicos()
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods:{
    moment(data){
      return moment(data)
    },
    urlToken:  function (url) {
      return url+"?level_token="+localStorage.getItem('level_token')
    },
    getServicos: function(){
        let self = this
        self.$http.get('/auth/departamento-terminados').then(response => {
            self.servicos = response.data.servicos
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    resetServico: function (produto) {
        let self = this
        if (!produto.id) return '';
        self.$http.get('/auth/departamento-reset/'+produto.id).then(() => {
            //self.servicos = response.data.servicos
            self.getServicos()
        })
        .catch(e => {
            this.errors.push(e)
        })
    }
  },
}
</script>